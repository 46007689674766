
import TextBlock from '~/components/TextBlock'
import QuoteBlock from '~/components/QuoteBlock'
import ChecklistBlock from '~/components/ChecklistBlock'

const getBlockComponent = (type) => {
  const types = {
    'textBlock': TextBlock,
    'quoteBlock': QuoteBlock,
    'checklistBlock': ChecklistBlock
  }

  return Object.keys(types).reduce((acc, key) => {
    if (type.endsWith(`${key}_BlockType`))
      return types[key]
    return acc
  }, TextBlock)
}

const Block = ({ __typename, ...props }) => {
  return getBlockComponent(__typename)(props)
}

export default Block

import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Paragraph,
  Heading,
  Container,
  Spacer,
  Row,
  Col,
  Markup
} from '~/styles/components'

const Masthead = ({
  sectionHeading,
  heading,
  subheading,
  body
}) => {

  return (
    <Box>
      <Spacer size="md" />
      <Container>
        <Row>
          <Col sm={10}>
            {sectionHeading &&
              <Heading level="2">
                <i>{sectionHeading}</i>
              </Heading>
            }
            {heading &&
              <Markup
                role="heading"
                aria-level="1"
                typography="headingExtraLarge"
                html={heading}
              />
            }
            <Spacer size={{ _: 'xxs', lg: 'sm' }} />
            {subheading &&
              <Paragraph large>
                {subheading}
              </Paragraph>
            }
            {body &&
              <Paragraph>
                {body}
              </Paragraph>
            }
          </Col>
        </Row>
      </Container>
      <Spacer size="md" />
    </Box>
  )
}

Masthead.propTypes = {
  sectionHeading: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  body: PropTypes.string,
}

export default Masthead
import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Spacer,
  Container,
  Row,
  Col,
  Bullet,
  Heading,
  Paragraph
} from '~/styles/components'

import { ListItem } from './style'

const ChecklistBlock = ({
  number,
  heading,
  subheading,
  checklistHeading,
  checklistSubheading,
  checklistItems = []
}) => {
  return (
    <Box>
      <Spacer size={{ _: 'md', lg: 'lg' }} />
      <Container>
        <Row>
          <Col lg={6} marginTop={{ _: 0, lg: '-0.75rem' }}>
            <Heading
              level="2"
              display="flex"
              alignItems="center"
            >
              {number &&
                <Bullet
                  typography="headingNumber"
                  bg={['yellow', 'purple', 'green'][number - 1]}
                  color={['black', 'white', 'white'][number - 1]}
                  number={number}
                />
              }
              {heading}
            </Heading>
            <Paragraph large>
              {subheading}
            </Paragraph>
            <Spacer size="sm" />
          </Col>
          <Col lg={6}>
            <Box
              bg="cream"
              padding="3rem"
              borderRadius="3rem"
            >
              <Heading level="3">
                {checklistHeading}
              </Heading>
              <Paragraph>
                {checklistSubheading}
              </Paragraph>
              <ul>
                {checklistItems.map((item, i) =>
                  <ListItem key={i}>
                    <Heading level="4">
                      {item.title}
                    </Heading>
                    <Paragraph>
                      {item.content}
                    </Paragraph>
                  </ListItem>
                )}
              </ul>
            </Box>
          </Col>
        </Row>
      </Container>
      <Spacer size={{ _: 'md', lg: 'lg' }} />
    </Box>
  )
}

ChecklistBlock.propTypes = {
  number: PropTypes.number,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  checklistHeading: PropTypes.string,
  checklistSubheading: PropTypes.string,
  checklistItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string
    })
  ),
}

export default ChecklistBlock

import styled from 'styled-components'
import theme from '~/styles/theme'
import { Icon } from '~/styles/components'

export const QuoteCard = styled.div`
  position: relative;
  padding: 3rem;
  z-index: 1;

  &:before {
    content: '';
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: skewX(-2deg);
    background: ${theme.colors.cream};
    border-radius: 3rem;
  }
`

export const QuoteMarks = styled.div`

  ${Icon} {
    position: absolute;
    width: 4rem;
    height: 4rem;
  }

  ${Icon}:first-child {
    top: -1rem;
    left: -1rem;
  }

  ${Icon}:last-child {
    bottom: -1rem;
    right: -1rem;
    transform: rotate(180deg);
  }
`
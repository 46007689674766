import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Metadata from '~/components/Metadata'
import Masthead from '~/components/Masthead'
import Block from '~/components/Block'

import { mapCraft } from './mappings'

import { Spacer } from '~/styles/components'

const PagesTemplate = ({ data }) => {
  const craft = mapCraft(data.craft)
  const {
    title,
    metadata,
    masthead,
    blocks
  } = craft.entry
  
  return (
    <div>
      <Metadata
        title={title}
        {...metadata}
      />
      <Masthead
        {...masthead}
      />
      {blocks.map(Block)}
      <Spacer size="lg" />
    </div>
  )
}

PagesTemplate.propTypes = {
  data: PropTypes.object
}

export default PagesTemplate

export const query = graphql`
query PagesTemplateQuery($id: Craft_QueryArgument) {
  craft {
    entry(id: [$id]) {
      ... on Craft_pages_pages_Entry {
        title
        metadata {
          ... on Craft_metadata_BlockType {
            title: metadataTitle
            description: metadataDescription
            keywords: metadataKeywords
            noFollow: metadataNoFollow
            noIndex: metadataNoIndex
          }
        }
        masthead {
          ... on Craft_masthead_BlockType {
            heading
            subheading
            body
          }
        }
        blocks {
          __typename
          ... on Craft_blocks_textBlock_BlockType {
            heading
            number
            subheading
            body
          }
          ... on Craft_blocks_quoteBlock_BlockType {
            author
            quote
            color
          }
        }
      }
    }
  }
}
`
import React from 'react'

import {
  Spacer,
  Container,
  Row,
  Col,
  Heading,
  Paragraph,
  Icon
} from '~/styles/components'

import { QuoteCard, QuoteMarks } from './style'

const QuoteBlock = ({
  quote,
  author,
  color
}) => {
  return (
    <div>
      <Spacer size={{ _: 'md', lg: 'lg' }} />
      <Container>
        <Row>
          <Col lg={6} />
          <Col lg={6}>
            <QuoteCard>
              <QuoteMarks>
                <Icon color={color} name="quote" />
                <Icon color={color} name="quote" />
              </QuoteMarks>
              <Heading color={color} level={4}>
                {author}
              </Heading>
              <Paragraph large>
                {quote}
              </Paragraph>
            </QuoteCard>
          </Col>
        </Row>
      </Container>
      <Spacer size={{ _: 'md', lg: 'lg' }} />
    </div>
  )
}

export default QuoteBlock
import styled from 'styled-components'
import checkmarkSVG from '~/assets/images/checkmark.svg'

export const ListItem = styled.li`
  padding-left: 2.75rem;

  &:before {
    content: '';
    width: 2rem;
    height: 2rem;
    background-image: url('${checkmarkSVG}');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`
import React from 'react'
import PropTypes from 'prop-types'

import {
  Spacer,
  Container,
  Row,
  Col,
  Heading,
  Paragraph,
  Bullet,
  Markup
} from '~/styles/components'

const TextBlock = ({
  heading,
  subheading,
  body,
  number
}) => {
  return (
    <div>
      <Spacer size={{ _: 'md', lg: 'lg' }} />
      <Container>
        <Row>
          <Col lg={6} marginTop={{ _: 0, lg: '-0.75rem' }}>
            <Heading
              level="2"
              display="flex"
              alignItems="center"
            >
              {number &&
                <Bullet
                  typography="headingNumber"
                  bg={['yellow', 'purple', 'green'][number - 1]}
                  color={['black', 'white', 'white'][number - 1]}
                  number={number}
                />
              }
              {heading}
            </Heading>
            <Paragraph large>
              {subheading}
            </Paragraph>
            <Spacer size="sm" />
          </Col>
          <Col lg={6}>
            <Markup
              typography="paragraph"
              html={body}
            />
          </Col>
        </Row>
      </Container>
      <Spacer size={{ _: 'md', lg: 'lg' }} />
    </div>
  )
}

TextBlock.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  body: PropTypes.string,
  number: PropTypes.number
}

export default TextBlock